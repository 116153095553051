import React from 'react'
import AutoMenu from '../AutoMenu'
import {Link} from 'react-router-dom'
export default function () {
  return(
    <div className="landing-page-wrapper">
      <div className="text-section">
        <div className="header">
          <div className="deco">
            {/* <img src="https://afag.imgix.net/sidewalk-grill/floral-icon.png?auto=format" alt=""/> */}
            <h1>
              {/* <div className="title">Muiishi Makirritos</div> */}
              {/* <br/> */}
              {/* <span className="subtitle">Available for Catering</span> */}
            </h1>



          </div>
        </div>



      </div>
      <AutoMenu
        tagsToShow={['regular']}
        ></AutoMenu>

    </div>
  )
}
