import React from 'react'
// import './specials.scss'
import AutoMenu from '../../react/AutoMenu'
import Form from './Form'
import {Link} from 'react-router-dom'
export default function () {
  return(
    <div className="catering-page">
      <Form></Form>
    </div>
  )
}
