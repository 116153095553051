
import * as Components from "./Muiishi"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "muiishi"
}
