import React from 'react'
import AutoMenu from './AutoMenu'
import {Link} from 'react-router-dom'
import './order.scss'
export default function () {
  return(
    <div className="ordering">
      {/* <div className="top-banner" style={{backgroundImage: "url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_fill,w_1200/ure9ymqsporphmwvvitt)"}}>
        <h1>Catering</h1>
      </div> */}
      {/* <div className="header">
        <h1><span className="text">Order Pickup / Delivery</span></h1>
      </div> */}
      {/* <p>Order</p> */}
      {/* <h2 id="top">Order Online</h2> */}

      <div className="ordering">
        <AutoMenu />
      </div>


      {/* <div className="old-menu">
        <h3>Looking for Makirritos Menu?</h3>

        <p>Our Regular Makirritos Menu is currently for special events only or catering.</p>

        <p><Link to="/catering">Muiishi Makirritos Menu</Link></p>
      </div> */}

    </div>
  )
}
