import React, { Component, PureComponent, useContext } from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom'
// import Carousel from 'nuka-carousel';
import About from './About'
import Order from './Order'
import Menu from './Menu'
import Specials from './Pages/Specials'
import Catering from './Pages/Catering'
import LogoBar from './svg/LogoBar'
import LogoLarge from './svg/LogoLarge'
// import Bakery from './Bakery'
// import GiftCards from './GiftCards'
// import Spirits from './Spirits'
// import Delivery from './Delivery'
import './index.scss'
import './ThemeToggle/theme_night.scss'
// import './wine.scss'
import ThemeContext from '../../Context/ThemeContext'

import {sections} from './sections'
import ThemeToggle from './ThemeToggle'
import CartBar from './CartBar'
import Updates from './Updates'
import AutoMenu from './AutoMenu'
import Schedule from './Schedule'
import Form from './Form'
import {injectShopContainer} from '../../Context/ShopData'
export {CartBar as StickyTopBar}




export const customOrderAppPaths = []

export const pages = [
  // {path: "/", label: "Home",  },
  {path: "/menu", label: "Menu", component: Menu },
  // {path: "https://nohoramenkitchenla.com/catering/", external: true, label: "Catering", component: Catering },

  {path: "/order", label: "Order", component: Order },
  {path: "/catering", label: "Catering", component: Catering },
  {path: "/updates", label: "Subscribe", component: Updates},
  // {path: "/schedule", label: "Events", component: Schedule },
  // {path: "https://nohoramenkitchenla.com/contact/", label: "Contact", external: true },

]

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label, external, hidden, unlisted}) => {
          if (hidden || unlisted) { return null}
          return (
          <li>
            {external ?
              <a className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>
        )})}
      </ul>
      <ThemeToggle></ThemeToggle>
    </nav>
  )}
}

const quickLinks = [
  // { to: '/order', label: 'order online'},
  { to: '/bakery', label: 'bakery'},
  { to: '/breakfast-lunch', label: 'marketplace'},
  { to: '/catering', label: 'catering'},
]

const foodImages = [
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-1.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-2.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-3.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-4.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-5.jpg"}
]



function Header (props) {
    const {settings} = props
    const {enableOrdering} = settings || {}
    let {deliveryAddress} = settings || {}
    let googleMapsLink = `https://maps.google.com/?q=${deliveryAddress}`
    deliveryAddress = (deliveryAddress || '').split(',')[0]

    const {themeName} = useContext(ThemeContext)
    const heroUrl = (themeName === 'dark') ?
      "https://afag.imgix.net/romancing-the-bean-cafe/espress-dark.jpg?w=500"
      :
     "https://afag.imgix.net/romancing-the-bean-cafe/espresso-light.jpg?w=500"
    return (
      <div className="landing-page-wrapper">
        {/* <div className="hero-wrapper">
          <div className="hero-banner">

            <div className="text-content">
              <h3 className="sub-truck-title"><span className="bg">new Japanese Taco Truck</span></h3>
              <Link to="/order" className="order-online-button">Order Pickup / Delivery</Link>
            </div>
          </div>
          <div className="logo-banner">
            <div className="text-content">
              <h3 className="sub-truck-title"><span className="bg">Muiishi Makirritos Truck</span></h3>
              <Link to="/catering" className="order-online-button">More Info</Link>
            </div>


          </div>
        </div> */}


        {/* <div className="movie-poster">
          <div className="text">
            <h2
              className="title"
              >Tacos<span className="dot">·</span>Muiishi!</h2>

            <div className="order-link-wrapper">
              <p>
                All New Menu
              </p>
              <p>Tacos, Burritos, Quesadillas & Loaded 🧨 Fries</p>

              <p>
                <Link to="/updates" className="order-online-button">Get Notified When We Launch ›</Link>
              </p>

              <p>
                <Link to="/order" className="order-online-button">Order Pickup / Delivery ›</Link>
              </p>
              <p>4pm Today at: <br/><a href={googleMapsLink}>{deliveryAddress}</a></p>
            </div>

            <div className="filling-name">Yakiniku</div>
            <div className="sub-title">Japanese grilled beef</div>
            <div className="filling-name">Japanese-Pastor</div>
            <div className="sub-title">Secret marinated pork butt</div>
            <div className="filling-name">Karaage</div>
            <div className="sub-title">Orignal, buffalo or kare (curry)</div>
            <div className="filling-name">Coconut Ebi</div>
            <div className="sub-title">Shrimp</div>
            <div className="filling-name">Tempura Veggies</div>
            <div className="sub-title">Grilled shiitake, sweet potatoes, eggplant & corn</div>
          </div>
        </div> */}

        {/* <div className="today-schedule">
          <h2 className="title">Today: June 16</h2>

          <h3>📍 Tikila's</h3>
          <p>4pm - 10pm</p>
          <p>2708 N Shepherd Dr, Houston, TX 77008</p>

          <Link to="/order" className="order-online-button">Order Pickup / Delivery</Link>
        </div> */}

        {/* <div className="schedule">


          <Schedule />
        </div> */}

        <div

          className="header-messege">
          {/* <h2>Schedule</h2>
          <p>Sat: The State of Mind 6-11pm (2632 north durham dr.)</p>
          <p>Sun: The State of Mind 6-11pm (2632 north durham dr.)</p>
          <p>Sunday: Tiki LA 3pm-10pm</p> */}

          <p>Join our <Link to="/updates">email list</Link> for opening notifications</p>
          {/* <p>Both Tacos and Makirritos menu available!</p> */}
          {/* <p>—— We will be opening all week very soon, we we do, you get 15% off!</p> */}

          {/* <Link to="/order" className="order-online-button">Order Pickup / Delivery</Link> */}
        </div>

        <div className="menu">
          <AutoMenu></AutoMenu>
        </div>

        <div className="location-info">
          <div className="text">
            {/* <div className="service">
              <h2>Pickup / Delivery</h2>
              <p></p>
            </div> */}
            <div className="location">
              <h2>
                Location
              </h2>
              <p>Today: {deliveryAddress}</p>
              <p>Soon we will have a fixed location.</p>
            </div>
            <div className="telephone">
              <h2>
                Telephone
              </h2>
              <p><a href="tel:+17134746456">(713) 474-6456</a></p>
            </div>
            <div className="hours-section">
              <h2>Hours</h2>
              {/* <p>Takeout</p> */}
              <div className="hours-details">
                <p>varies</p>
              </div>
            </div>
          </div>


        </div>



        {/* <div className="order-bottom-wrapper">
          <p>
            <a href="/order/?bottom" className="order-online-button">Order Pickup / Delivery ›</a>
          </p>
        </div> */}
        {/* <AutoMenu

        /> */}
        {/* <h2
          className="menu-header"
          style={{
            marginTop: '1em',
            textAlign: 'center',

          }}
          >Our Menu</h2> */}

        {/* <div
          style={{
            textAlign: 'center',
            marginTop: '1em'
          }}
          className="tp">
          <p>Orders over $30 get 2 free TP rolls (not the edible kind)</p>
        </div> */}

        {/* <div
          className="hero-banner">
          <div className="text">
            <h2 style={{

            }}>
            <span className="welcome">welcome to</span>
            <br/>
            <img
              style={{
                maxWidth: "350px"
              }}
              src="https://afag.imgix.net/electric-lotus/logo.png?gam=100&invert=true" alt=""/>

            </h2>

            <p>
              <Link to="/order" className="order-online-button">Order Pickup / Delivery</Link>
            </p>
          </div>
        </div>

        <div className="text-section">
          <div className="header">
            <div className="deco">
              <h1>
                <div className="title">Experience the Taste of India!</div>

              </h1>

            </div>
          </div>
          <div className="content">

            <p>We offer a richly-varied menu of fresh, natural ingredients, wholesome sauces and flavorful spices. All of our food is natural and freshly-prepared daily on the premises with no additives or preservatives.</p>
          </div>
        </div> */}

      {/* <AutoMenu
        width={600}
        ></AutoMenu> */}

      </div>







    );

}


export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-header">
        {/* <PoweredBy></PoweredBy> */}
      </div>
    );
  }
}

const InjectedHeader = injectShopContainer(Header)
export {InjectedHeader as Header}

// export function subMenuFilter (subMenu) {
//   const tag = subMenu.tag
//   if (!tag) {return true}
//
//   const now = new Date()
//   const day = now.getDay() // Sunday - Saturday : 0 - 6
//   const hours = now.getHours() //0 to 23
//   const minutes = now.getMinutes() // 0 to 59
//   const isWeekday = [1,2,3,4,5].indexOf(day) !== -1
//   const isBreakfast = (
//     (hours >= 0) && (hours < 11)
//   )
//   const isBrunch = (
//     (hours >= 0) && (hours < 15)
//   )
//   const isLunch = (
//     (hours >= 11) && (hours < 15)
//   )
//   const showLunchMenu = isWeekday && isLunch
//
//   const isDayBreakfast = tag.indexOf('weekday breakfast specials') !== -1 // m-f 6am - 11am
//   const isEndBreakfast = tag.indexOf('weekend breakfast specials') !== -1 // sat-sun 6am - 3pm
//   const isDayLunch =     tag.indexOf('weekday lunch specials') !== -1     // m-f 11am-3pm
//
//   if (isDayBreakfast && isWeekday && isBreakfast) { return true }
//   if (isEndBreakfast && !isWeekday && isBrunch) { return true }
//   if (isDayLunch && isWeekday && isLunch) {return true}
//   // if (isDinnerSubmenu && !showLunchMenu) { return true }
//
//   return false
// }
