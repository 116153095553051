import React from 'react'
// import './specials.scss'
import AutoMenu from './AutoMenu'
import {Link} from 'react-router-dom'
export default function () {
  return(
    <div className="catering-page">
             <div

className="header-messege">
{/* <h2>Schedule</h2>
<p>Sat: The State of Mind 6-11pm (2632 north durham dr.)</p>
<p>Sun: The State of Mind 6-11pm (2632 north durham dr.)</p>
<p>Sunday: Tiki LA 3pm-10pm</p> */}

<p>Join our <Link to="/updates">email list</Link> for opening notifications</p>
{/* <p>Both Tacos and Makirritos menu available!</p> */}
{/* <p>—— We will be opening all week very soon, we we do, you get 15% off!</p> */}

{/* <Link to="/order" className="order-online-button">Order Pickup / Delivery</Link> */}
</div>
      {/* <div className="top-banner" style={{backgroundImage: "url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_fill,w_1200/ure9ymqsporphmwvvitt)"}}>
        <h1>Catering</h1>
      </div> */}

      <AutoMenu 
        disableOrder={true}
        width={"600"}
        height={"600"}
      />

      {/* <div className="full-menu-link-wrapper">
        <Link to="/order" className="button">Go to Full Menu</Link>
      </div> */}
    </div>
  )
}
