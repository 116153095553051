import React from 'react'
import {Link} from 'react-router-dom'
import './Form.scss'

export default function () {
  return(
    <div className="form-wrapper-contact">
      <form name="contact" method="POST" data-netlify="true">
        <h2>Get Muiiishi Truck Locations</h2>
        <p>We'll also send you a 15% off coupon!</p>


        <input type="hidden" name="form-name" value="contact" />
        <p>
          <label>Your Name: <input type="text" name="name" /></label>
        </p>
        <p>
          <label>Your Email: <input type="email" name="email" /></label>
        </p>
        <p>
          <label>Optional Zipcode:
          <br/><input type="zipcode" name="zipcode" /></label>
        </p>
        {/* <p>
          <label>Message: <textarea name="message"></textarea></label>
        </p> */}
        <p>
          <button type="submit">Submit</button>
        </p>
      </form>
    </div>
  )
}
